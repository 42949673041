import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
// import arrowDark from '../../assets/img/arrow-dark.png';
import arrowDark from '../../../assets/img/arrow-dark.png'

const TechnologiesSlide = (props) => {
  const { children } = props;

  return (
    <div className={styles['slide__container']}>
      <div className={styles['slide__container__content']}>
        <div className={styles['slide__container__content__slogan']}>
          <div className={styles['slide__container__content__slogan__header']}>
            You choose - we use
          </div>
          <div className={styles['slide__container__content__slogan__text']}>
            Detailed research and deep understanding of your requirements allow us to match the most effective stack to your unique project.
          </div>
        </div>
        <a className={styles['slide__container__content__button']} href='#technologies'>
          <div className={styles['slide__container__content__button__content']}>
            <span>
              Find Out
            </span>
            <img src={arrowDark} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default TechnologiesSlide;