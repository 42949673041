import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Slide from '../Slide';
import MainSlide from '../Slide/MainSlide';
import ProductSlide from '../Slide/ProductSlide';
import TechnologiesSlide from '../Slide/TechnologiesSlide';


class Slider extends React.Component {
  state = {
    currentSlideIndex: 0,
    slides: [],
    sliderScheduleEntries: [],
    isSliderLocked: false
  }

  constructor() {
    super();
    this.state.slides = [
      {
        isCurrent: true,
        offScreenLaunched: false,
        onScreenLaunched: false
      },
      {
        isCurrent: false,
        offScreenLaunched: false,
        onScreenLaunched: false
      },
      {
        isCurrent: false,
        offScreenLaunched: false,
        onScreenLaunched: false
      }
    ];
  }

  changeSlide = (newSlideIndex, isManual, scheduleEntry) => {
    if (this.state.isSliderLocked || this.state.currentSlideIndex === newSlideIndex) {
      return;
    }

    if (isManual) {
      let entries = this.state.sliderScheduleEntries;
      entries = entries.map(entry => {
        entry.isCancelled = true;
        return entry;
      })
      this.setState({
        sliderScheduleEntries: entries
      })
    } else {
      let entries = this.state.sliderScheduleEntries.filter(entry => entry !== scheduleEntry);

      if (scheduleEntry.isCancelled) {
        return;
      }
      entries = entries.map(entry => {
        entry.isCancelled = true;
        return entry;
      })
      this.setState({
        sliderScheduleEntries: entries
      })
    }

    const slides = this.state.slides;
    slides[this.state.currentSlideIndex].offScreenLaunched = true;
    slides[newSlideIndex].onScreenLaunched = true;

    this.setState({
      currentSlideIndex: newSlideIndex,
      slides: slides,
      isSliderLocked: true
    })

    setTimeout(() => {
      this.setState({
        isSliderLocked: false
      })
      const delay = isManual ? 15000 : 6000;
      this.scheduleSlideChange(delay);
    }, 950);
  }

  scheduleSlideChange = (delay) => {
    const entries = this.state.sliderScheduleEntries;
    const scheduleEntry = {
      isCancelled: false
    }
    entries.push(scheduleEntry);

    this.setState({
      sliderScheduleEntries: entries
    })

    setTimeout(() => {
      let nextIndex = this.state.currentSlideIndex + 1;
      if (nextIndex >= this.state.slides.length) {
        nextIndex = 0;
      }

      this.changeSlide(nextIndex, false, scheduleEntry);
    }, delay);
  }

  flagSlideAnimationEnded = (slideIndex) => {
    const slides = this.state.slides;

    if (slides[slideIndex].onScreenLaunched) {
      slides[slideIndex].isCurrent = true;
    } else {
      slides[slideIndex].isCurrent = false;
    }

    slides[slideIndex].offScreenLaunched = false;
    slides[slideIndex].onScreenLaunched = false;

    this.setState({
      slides: slides,
    })
  }

  componentDidMount() {
    let dasf = this.props;    
    this.scheduleSlideChange(6000);
  }

  render() {

    return (
      <div className={classNames(styles['section'], styles['section-one'])}>
        <div className={styles['section__body']}>
          <div className={styles['section-one__slides']}>
            <div className={styles['section-one__slides__slide-wrapper']}>
              <Slide slide={this.state.slides[0]} onAnimationEnd={() => { this.flagSlideAnimationEnded(0) }} >
                <MainSlide >
                </MainSlide>
              </Slide>
            </div>
            <div className={styles['section-one__slides__slide-wrapper']}>
              <Slide slide={this.state.slides[1]} onAnimationEnd={() => { this.flagSlideAnimationEnded(1) }}>
                <ProductSlide >
                </ProductSlide>
              </Slide>
            </div>
            <div className={styles['section-one__slides__slide-wrapper']}>
              <Slide slide={this.state.slides[2]} onAnimationEnd={() => { this.flagSlideAnimationEnded(2) }}>
                <TechnologiesSlide >
                </TechnologiesSlide>
              </Slide>
            </div>
          </div>


          <div className={styles['section-one__slides__navs']}>
            {[...Array(this.state.slides.length)].map((x, i) =>
              <div className={styles['section-one__slides__navs__button-wrapper']}>
                <button key={i} onClick={() => this.changeSlide(i, true)} className={classNames(this.state.currentSlideIndex === i ? styles['current'] : '')}>

                </button>
              </div>
            )}
          </div>
        </div>
      </div>

    );
  }
}

export default Slider;
