import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../utils';
import styles from './index.module.scss';

export default connectTo(
    state => ({}),
    {},
    withNamespaces()(
        (props => {

            return (
                <footer>
                    <div className={styles['footer']}>
                        <div className={styles['footer__copyright']}>
                            © Company. All rights reserved 2019
                        </div>
                    </div>
                </footer>
            );
        })
    )
);
