import React from 'react';

class GenericNotFound extends React.Component {
    render() {
        return (
            <div> Not Found </div>
        )
    }
}

export default GenericNotFound;