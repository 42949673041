import { createReducer } from 'redux-act'

const DEFAULT_STATE = {
  //products: {}
}

export default createReducer(
  {
   
  },
  DEFAULT_STATE
)