import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
// import arrowDark from '../../assets/img/arrow-dark.png';
import arrowDark from '../../../assets/img/arrow-dark.png'

const ProductSlide = (props) => {
  const { children } = props;

  return (
    <div className={styles['slide__container']}>
      <div className={styles['slide__container__content']}>
        <div className={styles['slide__container__content__slogan']}>
          <div className={styles['slide__container__content__slogan__header']}>
            Create your individual feed using our smart plugin
          </div>
          <div className={styles['slide__container__content__slogan__text']}>
            Documentolos contains a backend module that aggregates installed social accounts, as well as several frontend adapters built with common UI technologies to include into end websites.
          </div>
        </div>
        <a className={styles['slide__container__content__button']} href='#contact'>
          <div className={styles['slide__container__content__button__content']}>
            <span>
              Contact Us
            </span>
            <img src={arrowDark} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default ProductSlide;