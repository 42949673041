import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

const Slide = (props) => {
  const { children, slide, onAnimationEnd } = props;

  return (

    <div className={classNames(styles['slide'], slide.isCurrent ? styles['current'] : '', slide.offScreenLaunched ? styles['off-screen'] : '', slide.onScreenLaunched ? styles['on-screen'] : '')}
      onAnimationEnd={onAnimationEnd}>
      {children}
    </div>

  );
}

export default Slide;