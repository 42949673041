import React from "react";
import { connectTo } from "../../utils";
import { withNamespaces } from 'react-i18next';
import { logout } from "../../actions/auth";
import styles from './index.module.scss';
import Scrollspy from 'react-scrollspy'
import logo from '../../assets/img/logo_main.jpg'
class Header extends React.Component {

  render() {
    const { i18n } = this.props;
    return (
      <header>
        <div className={styles['header']}>
          <div className={styles['header__brand']}>
            <a href='#company' className={styles['header__brand__content']}>
              <img src={logo} alt="logo" width="auto" height="58.5" />
            </a>
          </div>
          <div className={styles['header__bar']}>
            <div className={styles['header__bar__content']}>

              <Scrollspy items={['section-about', 'section-services', 'section-technologies', 'section-workflow', 'section-contact']} currentClassName={styles['active']} highlightAllItemsInView={true} className={styles['header__bar__content__router-links']}>
                <li >
                  <a className={styles['about']} href='#about'>About Us</a>
                </li>
                <li>
                  <a className={styles['services']} href='#services'>Services</a>
                </li>
                <li>
                  <a className={styles['technologies']} href='#technologies'>Technologies</a>
                </li>
                <li>
                  <a className={styles['workflow']} href='#workflow'>Workflow</a>
                </li>
                <li>
                  <a className={styles['contact']} href='#contact'>Contact Us</a>
                </li>
              </Scrollspy>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default connectTo(
  state => ({
    token: state.auth.token
  }),
  {
    logout
  },
  withNamespaces()(Header)
);
