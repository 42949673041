import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
// import arrowDark from '../../assets/img/arrow-dark.png';
import arrowDark from '../../../assets/img/arrow-dark.png'

const MainSlide = (props) => {
  const { children } = props;

  return (
    <div className={styles['slide__container']}>
      <div className={styles['slide__container__content']}>
        <div className={styles['slide__container__content__slogan']}>
          <div className={styles['slide__container__content__slogan__header']}>
            Where your ideas feel at home
          </div>
          <div className={styles['slide__container__content__slogan__text']}>
            Adopting the latest innovations in Software Development & Consulting, we turn the most ambitious ideas into successful projects. Most of them are in banking sector but we are also engaged in creating products for education and health care.
          </div>
        </div>
        <a className={styles['slide__container__content__button']} href='#services'>
          <div className={styles['slide__container__content__button__content']}>
            <span>
              Learn More
            </span>
            <img src={arrowDark} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default MainSlide;