import React from 'react'
import { withRouter } from 'react-router-dom'
import { login } from '../../actions/auth'
import { connectTo } from '../../utils'
import { withNamespaces } from 'react-i18next'
import Page from '../page'
import classNames from 'classnames'
import arrowWhite from '../../assets/img/arrow-white.png'
import arrowDark from '../../assets/img/arrow-dark.png'
import styles from './index.module.scss'
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor'
import Scrollspy from 'react-scrollspy'
import netLogo from '../../assets/img/icons/net.png'
import javaLogo from '../../assets/img/icons/java.png'
import rubyLogo from '../../assets/img/icons/ruby.png'
import jsLogo from '../../assets/img/icons/js.png'
import angularLogo from '../../assets/img/icons/angular.png'
import reactLogo from '../../assets/img/icons/react.png'
import nodejsLogo from '../../assets/img/icons/nodejs.png'
import Slider from '../../components/Slider'

class Landing extends React.Component {
  render () {
    return (
      <Page>
        <Scrollspy
          items={[
            'section-company',
            'section-about',
            'section-services',
            'section-technologies',
            'section-workflow',
            'section-contact'
          ]}
          componentTag={'div'}
        >
          <div id='section-company'>
            <ScrollableAnchor id={'company'}>
              <div />
            </ScrollableAnchor>
            <div className={styles['top-bar']} />
            <Slider />
            <div className={styles['return-arrow-section']}>
              <div className={styles['arrow-container']}>
                <a
                  className={classNames(styles['arrow-container__arrow-white'])}
                  href='#about'
                >
                  <img src={arrowWhite} alt='' />
                </a>
              </div>
            </div>
          </div>

          <ScrollableAnchor id={'about'}>
            <div />
          </ScrollableAnchor>
          <div id='section-about'>
            <div
              className={classNames(
                styles['section'],
                styles['section-two'],
                styles['text-section']
              )}
            >
              <div className={styles['section__body']}>
                <div className={styles['text-section__content']}>
                  <div
                    className={styles['text-section__content__body-container']}
                  >
                    <span
                      className={
                        styles['text-section__content__body-container__header']
                      }
                    >
                      About Us
                    </span>
                    <div
                      className={
                        styles[
                          'text-section__content__body-container__text-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Our consulting company was founded in 2011 in Vilnius,
                        Lithuania. The high level of expertise and the
                        commitment let us build strong and long-term relations
                        with our customers.
                      </div>
                      <br />
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Over the years, we acquired vast experience in the
                        development of custom software products in such fields
                        as FinTech, health care, education, science and
                        medicine.
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className={styles['arrow-container']}>
                  <a
                    className={styles['arrow-container__arrow-white']}
                    href='#services'
                  >
                    <img src={arrowWhite} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ScrollableAnchor id={'services'}>
            <div />
          </ScrollableAnchor>
          <div id='section-services'>
            <div
              className={classNames(
                styles['section'],
                styles['section-three'],
                styles['text-section'],
                styles['right']
              )}
            >
              <div className={styles['section__body']}>
                <div className={classNames(styles['text-section__content'])}>
                  <div
                    className={styles['text-section__content__body-container']}
                  >
                    <span
                      className={
                        styles['text-section__content__body-container__header']
                      }
                    >
                      Services
                    </span>
                    <div
                      className={
                        styles[
                          'text-section__content__body-container__text-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        The main direction we're working on is IT consulting.
                        <br />
                        Using the proven approaches, we help our clients to
                        realize their full working potential and improve
                        efficiency in a long-lasting prospective. Moreover,
                        we're also engaged in the development of high-quality
                        products, solutions and services in the IT sphere.
                      </div>
                      <br />
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Our team created and delievered various web applications
                        in different technologies to our clients across the
                        globe. As a result, we enriched our portfolio with
                        cost-effective and user-friendly solutions that helped
                        our clients to reach their business goals.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['arrow-container']}>
                  <a
                    className={styles['arrow-container__arrow-white']}
                    href='#technologies'
                  >
                    <img src={arrowWhite} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ScrollableAnchor id={'technologies'}>
            <div />
          </ScrollableAnchor>
          <div id='section-technologies'>
            <div
              className={classNames(
                styles['section'],
                styles['section-four'],
                styles['text-section']
              )}
            >
              <div className={styles['section__body']}>
                <div className={styles['text-section__content']}>
                  <div
                    className={styles['text-section__content__body-container']}
                  >
                    <span
                      className={
                        styles['text-section__content__body-container__header']
                      }
                    >
                      Technologies
                    </span>
                    <div
                      className={
                        styles[
                          'text-section__content__body-container__logo-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={netLogo} alt='' />
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={javaLogo} alt='' />
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={rubyLogo} alt='' />
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={jsLogo} alt='' />
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={angularLogo} alt='' />
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={reactLogo} alt='' />
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__logo-container__logo'
                          ]
                        }
                      >
                        <img src={nodejsLogo} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['arrow-container']}>
                  <a
                    className={styles['arrow-container__arrow-white']}
                    href='#workflow'
                  >
                    <img src={arrowWhite} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ScrollableAnchor id={'workflow'}>
            <div />
          </ScrollableAnchor>
          <div id='section-workflow'>
            <div
              className={classNames(
                styles['section'],
                styles['section-five'],
                styles['text-section'],
                styles['right']
              )}
            >
              <div className={styles['section__body']}>
                <div className={styles['text-section__content']}>
                  <div
                    className={styles['text-section__content__body-container']}
                  >
                    <span
                      className={
                        styles['text-section__content__body-container__header']
                      }
                    >
                      Workflow
                    </span>
                    <div
                      className={
                        styles[
                          'text-section__content__body-container__text-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Streamlined process is the key to the trusted
                        partnership. We strive to make our workflow as much
                        effective and transparent as possible.
                      </div>
                      <br />
                      <li
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Step 1: Data collection
                      </li>
                      <li
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Step 2: Analysis and estimation
                      </li>
                      <li
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Step 3: Solution and implementation
                      </li>
                      <li
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Step 4: Support
                      </li>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <div className={styles['arrow-container']}>
                  <a
                    className={styles['arrow-container__arrow-white']}
                    href='#contact'
                  >
                    <img src={arrowWhite} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ScrollableAnchor id={'contact'}>
            <div />
          </ScrollableAnchor>
          <div id='section-contact'>
            <div
              className={classNames(
                styles['section'],
                styles['section-six'],
                styles['text-section']
              )}
            >
              <div className={styles['section__body']}>
                <div className={styles['text-section__content']}>
                  <div
                    className={styles['text-section__content__body-container']}
                  >
                    <span
                      className={
                        styles['text-section__content__body-container__header']
                      }
                    >
                      Contact Us
                    </span>
                    <div
                      className={
                        styles[
                          'text-section__content__body-container__text-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Address:
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Jurgio Baltrušaičio g. 9-146, Vilnius 06145, Lietuvos
                        Respublika
                      </div>
                      <br />
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        Personal code:
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        302669676
                      </div>
                      <br />
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        VAT number:
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        LT100011491815
                      </div>
                      <br />
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        E-mail:
                      </div>
                      <div
                        className={
                          styles[
                            'text-section__content__body-container__text-container__text'
                          ]
                        }
                      >
                        softgreita@gmail.com
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['arrow-container']}>
                  <a
                    className={classNames(
                      styles['arrow-container__arrow-white'],
                      styles['up']
                    )}
                    href='#company'
                  >
                    <img src={arrowWhite} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles['return-arrow-section']}>
            <div className={styles['arrow-container']}>
              <a className={classNames(styles['arrow-container__arrow-white'], styles['up'])} href='#company'>
                <img src={arrowWhite} alt="" />
              </a>
            </div>
          </div> */}
          <ScrollableAnchor id={'footer'}>
            <div id='section-footer' />
          </ScrollableAnchor>
        </Scrollspy>
      </Page>
    )
  }
  // left: 37, up: 38, right: 39, down: 40, spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
  // keyCodes = {38: 1, 40: 1 };
  // sectionIds = ['company', 'about', 'services', 'technologies', 'workflow', 'contact', 'footer'];

  // currentSectionIndex = 0;
  // isScrollEnabled = true;

  // preventDefault = (e) => {
  //   e = e || window.event;
  //   if (e.preventDefault)
  //     e.preventDefault();
  //   e.returnValue = false;
  // }

  // preventDefaultForScrollKeys = (e) => {
  //   if (this.keyCodes[e.keyCode]) {
  //     this.preventDefault(e);
  //     return false;
  //   }
  // }

  // disableScroll = () => {
  //   if (window.addEventListener) // older FF
  //     window.addEventListener('DOMMouseScroll', this.preventDefault, false);
  //   window.onwheel = this.preventDefault; // modern standard
  //   window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
  //   window.ontouchmove = this.preventDefault; // mobile
  //   document.onkeydown = this.preventDefaultForScrollKeys;
  // }

  // enableScroll = () => {
  //   if (window.removeEventListener)
  //     window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
  //   window.onmousewheel = document.onmousewheel = null;
  //   window.onwheel = this.onWheelHandler;
  //   window.ontouchmove = null;
  //   document.onkeydown = null;
  // }

  // onWheelHandler = (e) => {
  //   if (!this.isScrollEnabled) {
  //     e.returnValue = false;
  //     return;
  //   }
  //   this.isScrollEnabled = false;
  //   this.disableScroll()

  //   if (e.wheelDeltaY > 0) {
  //     if (this.currentSectionIndex - 1 < 0) {
  //       this.enableScroll()
  //       this.isScrollEnabled = true;
  //       e.returnValue = false;
  //       return;
  //     }
  //     this.currentSectionIndex--;
  //     goToAnchor(this.sectionIds[this.currentSectionIndex], false)
  //   }

  //   if (e.wheelDeltaY < 0) {
  //     if (this.currentSectionIndex + 1 >= this.sectionIds.length) {
  //       this.enableScroll()
  //       this.isScrollEnabled = true;
  //       e.returnValue = false;
  //       return;
  //     }
  //     this.currentSectionIndex++;
  //     goToAnchor(this.sectionIds[this.currentSectionIndex], false)
  //   }

  //   setTimeout(() => {
  //     this.enableScroll()
  //     this.isScrollEnabled = true;
  //     e.returnValue = false;
  //     return;
  //   }, 440);

  //   e.returnValue = false;
  //   return;
  // }

  // updateCurrentSectionId(e) {
  //   for (let i = 0; i < this.sectionIds.length; i++) {
  //     if (e.id === 'section-' + this.sectionIds[i]) {
  //       this.currentSectionIndex = i;
  //       break;
  //     }
  //   }
  // }
}

export default connectTo(
  state => ({
    token: state.auth.token
  }),
  {
    login
  },
  withRouter(withNamespaces()(Landing))
)
